<template>
  <div id="about">
    <div class="admin_main_block">
      <div class="user_temp_title">{{ article.title }}</div>
      <div class="admin_table_main">
        <div class="page_main" v-html="article.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      article: {
        title: "",
        conent: "",
      },
    };
  },
  created() {
    this.getArticleList();
  },
  mounted() {},
  watch: {},
  methods: {

    getArticleList() {
      this.$request.post({
        url: "/ReportApi/business/article/getArticleInfo",
        params: {
          pageNo: 1,
          pageSize: 1,
          type: 5,
          isConent: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          if (res.data) {
            this.article = res.data[0];
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#about {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 855px;
    border-radius: 5px;
    padding: 20px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
</style>
